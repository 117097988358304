<template>
  <div class="location-display" @click="openLocationDialog">
    <i class="location-icon"></i>
    <span class="location-text" v-if="hasLocationName">
      {{ location.short_name }}
      <span class="coordinates-text">({{ formattedCoordinates }})</span>
    </span>
    <span class="location-text" v-else>
      {{ formattedCoordinates }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'LocationDisplay',
  computed: {
    location() {
      return this.$store.state.currentLocation;
    },
    hasCoordinates() {
      return this.location && typeof this.location.lat === 'number' && typeof this.location.lng === 'number';
    },
    hasLocationName() {
      return this.location.short_name && this.location.short_name !== this.$t('Unknown');
    },
    formattedCoordinates() {
      return `${this.location.lat.toFixed(2)}, ${this.location.lng.toFixed(2)}`;
    }
  },
  methods: {
    openLocationDialog() {
      this.$store.commit('setShowLocationDialog', true)
    }
  }
}
</script>

<style scoped>
.location-display {
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: rgba(46, 55, 77, 0.8);
  color: rgba(255, 255, 255, 0.9);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  z-index: 1000;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.location-display:hover {
  background-color: rgba(46, 55, 77, 0.9);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.location-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../assets/icon/locate.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.location-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.coordinates-text {
  font-size: 12px;
  opacity: 0.8;
}

@media (max-width: 599px) {
  .location-display {
    top: 10px;
    left: 10px;
    padding: 4px 10px;
    font-size: 12px;
  }

  .location-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  .location-text {
    max-width: 180px;
  }

  .coordinates-text {
    font-size: 10px;
  }
}
</style>
