export default {
    getUrlAllParams: function() {
        const url = window.location.href;
        let params = {};
        if (null == url || '' == url.trim()) return params;
        if (url.indexOf('?') == -1 || url.indexOf('?') + 1 > url.length)
            return params;
        let str = url.substring(url.indexOf('?') + 1);
        let objs = str.split("&");
        for (let i = 0; i < objs.length; i++) {
            let items = objs[i].split("=");
            let key = decodeURIComponent(items[0]).trim();
            let value = decodeURIComponent(items[1]).trim();
            try {
                params[key] = JSON.parse(value);
                continue;
            } catch (err) {}
            params[key] = value;
        }
        return params;
    },
    getUrlParams: function(variable) {
        const url = window.location.href;
        if (null == variable || '' == variable.trim()) return null;
        if (null == url || '' == url.trim()) return null;
        if (url.indexOf('?') == -1 || url.indexOf('?') + 1 > url.length)
            return null;
        let str = url.substring(url.indexOf('?') + 1);
        let objs = str.split("&");
        for (let i = 0; i < objs.length; i++) {
            let items = objs[i].split("=");
            let key = decodeURIComponent(items[0]).trim();
            let value = decodeURIComponent(items[1]).trim();
            if (key == variable) {
                try {
                    return JSON.parse(value);
                } catch (err) {
                    return value;
                }
            }
        }
        return null;
    },
    uuid: function() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        var uuid = s.join("");
        return uuid;
    },
    clipBoard: function(text) {
        const body = document.body;
        const input = document.createElement("input");
        body.append(input);
        input.style.opacity = 0;
        input.value = text;
        input.select();
        input.setSelectionRange(0, input.value.length);
        document.execCommand("Copy");
        input.blur();
        input.remove();
    },
   

 getRotationMatrix:function( alpha, beta, gamma ) {
  var degtorad = Math.PI / 180; // Degree-to-Radian conversion
  var _x = beta  ? beta  * degtorad : 0; // beta value
  var _y = gamma ? gamma * degtorad : 0; // gamma value
  var _z = alpha ? alpha * degtorad : 0; // alpha value

  var cX = Math.cos( _x );
  var cY = Math.cos( _y );
  var cZ = Math.cos( _z );
  var sX = Math.sin( _x );
  var sY = Math.sin( _y );
  var sZ = Math.sin( _z );

  //
  // ZXY rotation matrix construction.
  //

  var m11 = cZ * cY - sZ * sX * sY;
  var m12 = - cX * sZ;
  var m13 = cY * sZ * sX + cZ * sY;

  var m21 = cY * sZ + cZ * sX * sY;
  var m22 = cZ * cX;
  var m23 = sZ * sY - cZ * cY * sX;

  var m31 = - cX * sY;
  var m32 = sX;
  var m33 = cX * cY;

  return [
    m11,    m12,    m13,
    m21,    m22,    m23,
    m31,    m32,    m33
  ];

},



}
