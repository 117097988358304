import axios from 'axios';

//高德地图key
const keys = ['eeb8ebb3e77f016e1575316e6ac0e1ad', 'a25a7e6ac12d7e77c89cf77b981ba7d4'];
let keysLock = {};

function getKey() {
  for (let i in keys) {
    const key = keys[i];
    if (!keysLock[key]) {
      return key;
    }
  }
  return null;
}

function setKeyLock(key) {
  keysLock[key] = true;
}

function getDefaultLngLat() {
  return new Promise(resolve => {
    const body = document.body;
    const div = document.createElement("div");
    body.append(div);
    div.id = 'temp-map';
    div.style.zIndex = '-1';
    div.style.opacity = '0';
    div.style.width = '100px';
    div.style.height = '100px';
    const map = new AMap.Map('temp-map', {
      resizeEnable: false,
    });
    map.on("complete", function () {
      const {lng, lat} = map.getCenter();
      resolve({longitude: lng, latitude: lat});
      div.remove();
    });
  })
}

function getDefaultPlace() {
  return new Promise(resolve => {
    getDefaultLngLat().then((longitude, latitude) => {
      getPlace(longitude, latitude).then(res => {
        resolve(res);
      })
    })
  })
}

function getPlace(longitude, latitude) {
  const location = `${Number(longitude).toFixed(5)},${Number(latitude).toFixed(5)}`;
  return new Promise(((resolve, reject) => {
    const key = getKey();
    if (!key) resolve([]);
    axios.get(`https://restapi.amap.com/v3/geocode/regeo?key=${key}&location=${location}`).then(res => {
      if (res.status !== 200) resolve({});
      res = res.data;
      if (res.status == 1) {
        const info = res.regeocode.addressComponent;
        info.address = res.regeocode.formatted_address;
        if (info && info.adcode && info.adcode.length == 0) {
          info.adcode = ''
        }
        if (info && info.address && info.address.length == 0) {
          info.address = ''
        }
        if (info && info.city && info.city.length == 0) {
          info.city = ''
        }
        if (info && info.country && info.country.length == 0) {
          info.country = ''
        }
        if (info && info.district && info.district.length == 0) {
          info.district = ''
        }
        if (info && info.province && info.province.length == 0) {
          info.province = ''
        }
        resolve(info);
      } else {
        setKeyLock(key);
        getPlace(longitude, latitude).then(res => resolve(res)).catch(() => resolve({}));
      }
    }).catch(() => {
      resolve({});
    })
  }))
}

/**
 * 获取地点列表
 * @param value
 * @returns {Promise<any>}
 */
function getPlaceList(value, offset, city) {
  return new Promise((resolve) => {
    const key = getKey();
    if (!key) resolve([]);
    axios.get(`https://restapi.amap.com/v3/place/text?key=${key}&keywords=${value}&offset=${offset || 10}${city ? `&city=${city}` : ''}`).then(res => {
      if (res.status !== 200) resolve([]);
      res = res.data;
      if (res.status == 1) {
        let placeList = [];
        if (typeof (res.pois) == 'object' && res.pois.length) {
          res.pois.forEach(obj => {
            let location = obj.location.split(",");
            placeList.push({
              latitude: location[1],
              longitude: location[0],
              ...obj,
              place: obj.name,
              address: `${obj.pname || ''}${(obj.cityname || '') == (obj.pname || '') ? '' : (obj.cityname || '')}${obj.adname || ''}${obj.address || ''}`
            })
          });
          resolve(placeList);
        } else {
          if (typeof (res.suggestion) == 'object' && res.suggestion.cities && res.suggestion.cities.length) {
            let requests = [];
            for (let i = 0; i < res.suggestion.cities.length && i < 10; i++) {
              const item = res.suggestion.cities[i];
              if (item.citycode || item.name) {
                requests.push(getPlaceList(value, 1, item.citycode || item.name))
              }
            }
            Promise.all(requests).then(pres => {
              let results = [];
              pres.forEach(r => {
                results.push(...r)
              });
              resolve(results);
            }).catch(() => {
              resolve([]);
            })
          } else {
            resolve([]);
          }
        }
      } else {
        setKeyLock(key);
        getPlaceList(value, offset, city).then(res => resolve(res)).catch(() => resolve([]));
      }
    }).catch((err) => {
      resolve([]);
    });
  })
}

export default {
  getDefaultLngLat,
  getDefaultPlace,
  getPlace,
  getPlaceList
}
